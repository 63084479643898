<template>
  <!-- 개인 -->
  <section class="taxSection1 keep-all">
    <div class="container">
      <div class="row greeting">
        <div class="col-12 col-lg-5 ">
          <div class="pageTitle">개인기장</div>
          <div class="pageContent">깨끗한 장부<br>놓침없는 절세정보</div>
          <div class="pageTitleBtn1 col-12">
            <button class="btn btn-lg btn-primary mt-4" @click="contact">상담하기</button>
          </div>
        </div>
        <div class="col taxLottieBox">
          <!-- index.html에 로티스크립트 추가 -->
          <lottie-player class="taxLottieTest" src="https://assets9.lottiefiles.com/private_files/lf30_y1hMez.json"
            background="#FFFFFF00" speed="0.5" loop autoplay></lottie-player>
        </div>
        <div class="pageTitleBtn2 col-12">
          <button class="btn btn-lg btn-primary mt-4" @click="contact">상담하기</button>
        </div>
      </div>
    </div>
  </section>

  <section class="taxSection2 keep-all">

    <div class="serviceContents container">
      <div class="row px-lg-5">
        <div class="col-lg-3 col-sm-6 col-12 my-lg-5 my-4" v-for="a in services" :key="a">
          <div class="pageServiceTitle">{{ a.serviceTitle }}</div>
          <div class="pageServiceContent">{{ a.serviceContent }}</div>
          <div class="pageServiceContent">{{ a.serviceContent2 }}</div>
        </div>
      </div>
    </div>
    <div class="pageContent mt-5" style="font-weight:bold">수수료 100,000원 부터 <br><span style="font-size:1rem">(VAT
        미포함)</span>
    </div>
    <button class="btn btn-lg btn-primary mt-4" @click="contact">상담하기</button>
  </section>

  <!-- 법인 -->

  <section class="consultSection1">

    <div class="container">
      <div class="row greeting">
        <div class="col-12 col-lg-5 ">
          <div class="pageTitle">법인기장</div>
          <div class="pageContent">전략적인 솔루션으로<br>경쟁력을 제공합니다</div>
          <div class="pageTitleBtn1 col-12">
            <button class="btn btn-lg btn-primary mt-4" @click="contact">상담하기</button>
          </div>
        </div>
        <div class="col taxLottieBox">
          <!-- index.html에 로티스크립트 추가 -->
          <lottie-player class="taxLottieTest" src="https://assets9.lottiefiles.com/packages/lf20_rno3fs3h.json"
            background="#FFFFFF00" speed="0.5" loop autoplay></lottie-player>
        </div>
        <div class="pageTitleBtn2 col-12">
          <button class="btn btn-lg btn-primary mt-4" @click="contact">상담하기</button>
        </div>
      </div>
    </div>
  </section>

  <section class="consultSection2">

    <div class=" container">

      <div class="gradeInfo">
      <div class="gradeSelect my-2">서비스 범위를 선택하세요</div>
      <button class="btn mx-2" :class="[step==1?'btn-primary':'btn-outline-primary']" @mouseover="step==1?step=1:step=1">기장대리</button>
      <button class="btn mx-2" :class="[step==2?'btn-primary':'btn-outline-primary']" @mouseover="step==2?step=1:step=2">기장+자문</button>
      <button class="btn mx-2" :class="[step==3?'btn-primary':'btn-outline-primary']" @mouseover="step==3?step=1:step=3">기장+자문+경리</button>
    </div>

      <div class="row px-5">
        <div class="col-lg-4 col-12 my-lg-4 my-4" :class="[step>0 ? 'selectedY' : 'selectedN']" @click="step==1?step=1:step=1">
          <div class="pageServiceTitle">
            기장대리
          </div>
          <div class="pageServiceContent">
            4대보험<br />
            원천세<br />
            부가세<br />
            법인세*<br />
            <span class="additional-information">(*연1회 별도과금)</span>
          </div>
        </div>
        <div class="col-lg-4 col-12 my-lg-4 my-4 " :class="[step>1 ? 'selectedY' : 'selectedN']" @click="step==2?step=1:step=2">
          <div class="pageServiceTitle">
            + 세무자문
          </div>
          <div class="pageServiceContent">
            임원상여 설계<br />
            퇴직금 설계<br />
            지분구조 전략<br />
            기타 지원금 안내
          </div>
        </div>
        <div class="col-lg-4 col-12 my-lg-4 my-4" :class="[step>2 ? 'selectedY' : 'selectedN']" @click="step==3?step=1:step=3">
          <div class="pageServiceTitle">
            + 경리아웃소싱
          </div>
          <div class="pageServiceContent">
            인사+노무<br />
            - 근로계약서<br />
            - 연차대장<br />
            - 기업규칙<br />
            Payroll<br />
            세금계산서 발급<br />
            증빙관리<br />
          </div>
        </div>
      </div>
  
    <div class="my-3"></div>
    <div class="pageContent"  v-if="step==1" style="font-weight:bold">수수료 150,000원 부터 <br><span style="font-size:1rem">(VAT
        미포함)</span>
    </div>
    <div class="pageContent" v-if="step==2" style="font-weight:bold">수수료 500,000원 부터 <br><span style="font-size:1rem">(VAT
        미포함)</span>
    </div>
    <div class="pageContent" v-if="step==3"  style="font-weight:bold">수수료 1,500,000원 부터 <br><span style="font-size:1rem">(VAT
        미포함)</span>
    </div>
  </div>
    
    <button class="btn btn-lg btn-primary mt-4" @click="contact2">상담하기</button>

  </section>




</template>

<script>
export default {
  name: 'Tax-a',
  data() {
    return {

      step:1,

      services: [
        { icon: 'fi fi-sr-chat-arrow-grow', serviceTitle: '손익점검', serviceContent: '귀사의 손익을 사전에 철저히 분석하고, 맞춤형 고급 컨설팅을 통해 경영 효율을 높여드립니다.'},
        { icon: 'fi fi-rr-piggy-bank', serviceTitle: '절세방안', serviceContent: '최신 세법에 따른 절세 전략을 제시하고, 업데이트된 정보를 빠르게 제공하여 세금 부담을 줄여드립니다.'},
        { icon: 'fi fi-rr-coins', serviceTitle: '국비지원', serviceContent: '인건비 관련 국가지원금을 최대한 활용할 수 있도록 맞춤형 지원 정보를 제공하여 비용 절감을 돕습니다.' },
        { icon: 'fi fi-rr-comments', serviceTitle: '신속소통', serviceContent: '업무용 카카오톡을 통해 빠르고 정확한 답변을 제공, 실시간으로 신속한 소통을 보장합니다.' },
      ]
    }
  },
  methods: {
    contact() {
      //To.Contact
      this.emitter.emit('contact', '개인기장')
    },
    contact2() {
      //To.Contact
      this.emitter.emit('contact', '법인기장')
    },
  },

}
</script>

<style scoped>

.keep-all {
  word-break: keep-all;
}

.taxSection1 {
  padding-bottom: 20px;
}

.greeting {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageTitle {
  font-size: 3rem;
  margin-bottom: 15px;
  color: var(--primary);
  font-weight: bold;
}

.pageContent {
  font-size: 1.5rem;
}


.pageTitleBtn1 {
  display: block;
}

.pageTitleBtn2 {
  display: none;
}

@media (max-width : 768px) {
  .pageTitleBtn1 {
    display: none;
  }

  .pageTitleBtn2 {
    display: block;
  }
}

.pageServiceTitle {
  color: var(--primary);
  font-size: 2rem;
  font-weight: bolder;
  padding: 10px;
}

.pageServiceContent {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 5px;
}

.gradeInfo{
  font-size: 2rem;
  font-weight: bolder;
  margin: auto;
  justify-content: center;
}

.gradeCard{
  position: relative;
  width: 250px;
  height: 300px;
  border: solid 1px;
}

.selectedY{
  opacity: 1;
  transition: all 1s;
}
.selectedN{
  opacity: 0.1;
  transition: all 0.5s;
}
.gradeItem{
  font-size: 1.2rem;
}
.gradePrice{
  font-size: 1.2rem;
}

.taxLottieTest {
  height: 30vw;
  min-height: 380px;
  max-height: 500px;
}

.taxSection2 {
  background: #F7F9FF;
  padding-top: 20px;
  padding-bottom: 50px;
}

.serviceContents2 {
  padding-left: 150px;
  padding-right: 150px;
}

@media (max-width : 768px) {
  .serviceContents2 {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.additional-information {
  font-size: 1rem;
  color: var(--primary);
  font-weight: 500;
}

.consultSection1 {
  padding-top: 50px;
  padding-bottom: 50px;
}

</style>