<template>
    <div class="contactModal" v-if="contactModalState">
        <div class="black-bg">
            <div class="contactSelectBox">
                <div class="contactTitle forMobileBtn">02-6939-1131</div>
                <div class="row forMobileBtn">
                    <div class="col contactCall">
                        <button class="btn btn-primary" @click="call()">
                            <i class="bi bi-telephone-forward-fill"></i>
                            <div>전화</div>
                        </button>
                    </div>
                    <div class="col contactMsg">
                        <button class="btn btn-primary" @click="message()">
                            <i class="bi bi-chat-left-text-fill"></i>
                            문자
                        </button>
                    </div>
                </div>       
                <button class="btn btn-primary closeModalBtnBox mx-2" @click="contactModalState = false">닫기</button>    
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: 'Contact-a',

    data() {
        return {
            contactModalState: false,
            addMsg: '',
            isInputed: false,
            mobileNumber: '',
            isRegist: false,
        }
    },

    methods: {

        call() {
            location.href = 'tel:0269591131'
        },

        message() {
            location.href = 'sms:01095113765' + (checkMobile() === 'ios' ? '&' : '?') + 'body=' + '안녕하세요. <' + this.addMsg + '> 상담을 신청합니다'

            function checkMobile() {
                var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

                if (varUA.indexOf('android') > -1) {    // 안드로이드
                    return "android";
                } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1 || varUA.indexOf('ios') > -1) {    // IOS
                    return "ios";
                } else {    // IOS, 안드로이드 외
                    return "other";
                }
            }

        }
    },

    mounted() {

        //from.Consult/Tax
        this.emitter.on('contact', (a) => {
            this.contactModalState = true
            this.addMsg = a
        })
    }
}


</script>

<style scoped>
.black-bg {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
}

.contactSelectBox {
    position: relative;
    margin: auto;
    /* margin-top: 150px; */
    width: 80%;
    max-width: 300px;
    height: 300px;
    background: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeModalBtnBox {
    position: absolute;
    bottom: 20px;
}

.contactTitle {
    position: absolute;
    top: 40px;
}

.contactSelectBox button {
    width: 90px;
}

.contactSelectBox button i {
    display: block;
    margin: 5px;
}

.registed {
    position: absolute;
    bottom: 80px;
}

.regist {
    animation: blink 0.5s 5;
}

@keyframes blink {
    from {
        opacity: 0.5;

    }

    to {
        opacity: 1;

    }
}
</style>