<template>

  <!-- 앨범 -->
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-4 col-6 imgContainer" v-for="a in officeImages" :key="a" style="padding:2px">
        <div class="imgBox" @click="openModal(a)" :style="`background-image:url(${a})`">
        </div>
      </div>
    </div>

    <h3 class="my-5"></h3>
    <p @click="addressCopy" style="cursor:pointer">서울 강남구 논현로158길 8 단일빌딩 102호<br>
      <span class="subwayInfo text-black-50">압구정역(3호선) 3번 출구 490m </span><span @click="openLink" class="badge bg-primary">네이버지도 열기</span>
    </p>
    <div class="copyModal" v-if="copyModal">
      주소가 복사되었습니다
    </div>
    <!-- 지도 -->
    <div class="row map-wrapper my-3">
      <iframe class="col-12"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1349.8287653863051!2d127.02808967982224!3d37.521481631047095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca30638a8bd8f%3A0x67a866c43e930461!2z7Iug7Z2l7IS466y07ZqM6rOE7IKs66y07IaM!5e0!3m2!1sko!2skr!4v1669724961412!5m2!1sko!2skr"
        style="border:0; padding-left: 0px;padding-right: 0px;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>

  <h3 class="my-2"></h3>

  <p>월-금 09:30-17:30<br>주말,공휴일은 휴무입니다</p>

  <!-- 모달창 -->
  <div v-if="modalState == true" class="black-bg" @click="closeModal">
    <div class="white-bg">
      <div>
        <img class="modalImg" @click="closeModal" :src="clickedImg">
      </div>
      <div class="modalCloseBtn">
        <button type="button" class="btn btn-primary mt-2" @click="closeModal">닫기</button>
      </div>
    </div>
  </div>





</template>

<script>

import { db } from '../main.js';

export default {
  name: 'Office-a',

  data() {
    return {
      copyModal: false,
      clickedImg: '',
      modalState: false,
      officeImages: []
    }
  },

  methods: {
    openLink() {
      window.open('https://naver.me/5Y1OW0MI', '_blank');
    },
    copyMadalOff() {
      this.copyModal = false
    },

    addressCopy() {
      navigator.clipboard.writeText('서울 강남구 논현로158길 8, 102호');
      this.copyModal = true
      setTimeout(this.copyMadalOff, 2000)
    },

    openModal(a) {
      this.modalState = true
      this.clickedImg = a
    },

    closeModal() {
      this.modalState = false
    }


  },

  mounted() {

    //orderBy('Date','desc') desc 추가하면 역순으로 불러옴
    db.collection('officeAlbum').orderBy('Date').get().then((res) => {
      console.log('hi I`m here')
      res.forEach((res2) => {
        // console.log(res2.data().officeImg)
        this.officeImages.push(res2.data().officeImg)
      })
    })
  }
}
</script>

<style scoped>
p {
  font-size: 1.1rem;
}

.copyModal {
  color: #0d6efd;
  animation-duration: 0.5s;
  animation-name: copyModal;
}

@keyframes copyModal {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.map-wrapper {
  position: relative;
}

/* 반응형 박스사이즈 비율 유지 */
.map-wrapper::after {
  content: "";
  display: block;
  padding-bottom: 50%;
}

.map-wrapper iframe {
  /* 구글지도는 상위태그로 감싼 상태에서 설정해야댐 */
  position: absolute;
  width: 100%;
  height: 100%;
}

@media (max-width : 991px) {
  .map-wrapper::after {
    content: "";
    display: block;
    padding-bottom: 80%;
  }
}

.imgBox {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.imgBox::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.imgBox:hover {
  opacity: 80%;
}

div {
  box-sizing: border-box;
}

.black-bg {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;

  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(2px);
  z-index: 100;
}

.white-bg {
  position: absolute;
  width: fit-content;
  background: white;
  border-radius: 8px;
  padding: 10px;
  margin: auto;

  animation-duration: 0.5s;
  animation-name: imageSelect;
}

@keyframes imageSelect {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}


.modalImg {
  max-width: 80vw;
  max-height: 80vh;
}
</style>