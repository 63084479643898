import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";

//npm install mitt
import mitt from "mitt";
let emitter = mitt();
let app = createApp(App);
app.config.globalProperties.emitter = emitter;
//참고로 app.config.globalProperties 이 부분이 모든 컴포넌트에서 사용할 수 있는 변수같은걸 등록할 수 있게 도와주는 일종의 object 자료입니다.
// 글로벌 변수 보관함이라고 보시면 되겠네요.
// 이런 데다가 자주 쓰는 axios 이런 것도 보관하면 편리합니다. import 안해와도 axios.get 할 수 있음
// 다만 this.axios.get 이렇게 하셔야합니다.

//npm install firebase@8.6.5
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyCjru5SkA26ULpz-tebAnHulhMWBcTfkPE",
  authDomain: "shtax-5ad99.firebaseapp.com",
  projectId: "shtax-5ad99",
  storageBucket: "shtax-5ad99.appspot.com",
  messagingSenderId: "66458097564",
  appId: "1:66458097564:web:3baf4b758980a644ce9a59",
  measurementId: "G-BGGSYP9Y8K",
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();

//vuex 설치 후 임폴트
//npm install vuex@next 로 설치 후 store.js 만들고 임폴트
import store from "./store.js";

// createApp(App)=>app *mitt설치후 변경됨
app.use(store).use(router).use(bootstrap).mount("#app");
