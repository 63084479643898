<template>
    <footer class="footerBar mt-5" id="footerBar">
        <span class="footerText">상호 </span>신흥세무회계 &nbsp;
        <span class="footerText">대표 </span>김성은 공인회계사 &nbsp;
        <span class="footerLine">
            <span class="footerText">사업자등록번호 </span>141-43-00142 &nbsp;
        </span>
        <br class="footerBr"><span class="footerText">주소 </span>서울 강남구 논현로158길 8 단일빌딩 102호 &nbsp;
        <span class="footerLine">
            <span class="footerText">연락처 </span>02-6959-1131
        </span>
    </footer>

</template>

<script>

export default
    {
        name: 'Footerbar-a',

        mounted() {
            let observer = new IntersectionObserver((e) => {
                e.forEach((box) => {
                    if (box.isIntersecting) {
                        setTimeout(() => {
                            box.target.style.opacity = 1;
                        }, 200)
                    }
                    else {
                        box.target.style.opacity = 0;
                    }
                })
            })
            let footerDiv = document.getElementById('footerBar')
            observer.observe(footerDiv)
        },



    }
</script>

<style>

.nextUrl{
    border: solid 1px rgba(255, 255, 255, 0);
}

.footerText {
    color: #0d6efd;
}

.footerBar {
    padding-top: 30px;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 150px;
    opacity: 0;
    transition: all 1s;
}

/* 768px 보다 작으면 아래 적용됨 */
@media(max-width : 768px) {
    .footerLine {
        display: block;
    }

    .footerBr {
        display: none;
    }

    .footerBar {
        font-size: medium;
    }
}
</style>