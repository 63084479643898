import { createWebHashHistory, createRouter } from "vue-router";
//HashHistory
//npm install vue-router@4

import Main from "./pages/Main";
import Request from "./pages/Request";
import Tax from "./pages/Tax";
import Office from "./pages/Office";
import Financing from "./pages/Financing";

const routes = [
  {
    path: "/",
    component: Main,
  },
  {
    path: "/request",
    component: Request,
  },
  {
    path: "/tax",
    component: Tax,
  },
  {
    path: "/office",
    component: Office,
  },
  {
    path: "/financing",
    component: Financing,
  },
];

//webHashHistory로 해야 웹페이지 새로고침해도 문제없음
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    //페이지 이동 시, 스크롤 탑 으로 고정
    return { top: 0 };
  },
});

export default router;
