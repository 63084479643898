<template>
  <section class="mainSection1">
    <div class="container pt-4">
      <div class="row greeting">
        <div class="col-12 col-lg-5">
          <div class="pageTitle">회계, 그 이상<span v-if="$store.state.existGuest"
              style="font-weight: bolder; color:#0d6efd;">.</span></div>
          <div class="pageContent">좋은 회계사는 <br>그만한 가치가 있습니다</div>
        </div>
        <div class="col lottieBox">
          <!-- index.html에 로티스크립트 추가 -->
          <lottie-player class="lottieTest" src="https://assets5.lottiefiles.com/packages/lf20_3rqwsqnj.json"
            background="#FFFFFF00" speed="1" loop autoplay></lottie-player>
        </div>
      </div>
    </div>
  </section>

  <section>
    <div class="container">

    </div>
  </section>

  <section class="mainSection2">
    <div class="container">
      <div class="introduce mt-3">
        안녕하세요. <span class="text">신흥세무회계 김성은 회계사</span>입니다.<br />
        사무실을 운영하며 항상 다짐했던 것이<span> '교감을 가지고 고객 관점에서 생각하자'</span> 였습니다.<br />
        업계에서 관행처럼 이루어지고 있던 업무프로세스를 지난 10년간 고객 관점에서 생각하며<br />
        꾸준히 연구하고 개선했기에 고객만족으로 성장하는 회사가 될 수 있었습니다.<br />
        이 마음 변치 않고 끊임없이 발전해서 만족스러운 서비스로 보답하겠습니다.
      </div>

      <div class="introduceMobile">
        안녕하세요.<br /><span>신흥세무회계 김성은 회계사</span>입니다.<br />
        사무실을 운영하며 항상 다짐했던 것이<span> '교감을 가지고 고객 관점에서 생각하자'</span> 였습니다.
        업계에서 관행처럼 이루어지고 있던 업무프로세스를 지난 10년간 고객 관점에서 생각하며
        꾸준히 연구하고 개선했기에 고객만족으로 성장하는 회사가 될 수 있었습니다.
        이 마음 변치 않고 끊임없이 발전해서 만족스러운 서비스로 보답하겠습니다.
      </div>


      <div class="profileBox mt-3">

        <img src="@/assets/저용량프사.png" alt="프로필사진">

        <div class="profileTitle" id="profileTitle">
          <li>2011 연세대학교 경영학과 졸업</li>
          <li>2011 한국공인회계사 합격</li>
          <li>2011~2014 안진회계법인 TAX 본부</li>
          <li>2016~ 신흥세무회계사무소 대표회계사</li>
          <li>2018~2022 나눔회계사, 영세납세지원단 활동</li>
          <li>2022 납세자보호위원 활동</li>
          <li>202308~ 채움 여성공인회계사회 소식지 편집위원</li>
          <li>2022 스파크랩 전문가멘토링 활동</li>
          <li>2023 KOICA 전문가멘토링 활동</li>

          <li>202103~202106 (주)스파크펫 CFO</li>
          <li>202112~202206 그래비티인베스트(주) 감사</li>
          <li>202207~ 그래비티자산운용(주) 감사</li>

          <li>2021 납세자의 날 모범납세자 상장 수여</li>
          <li>2023 한국공인회계사회, 회계산업 발전기여 표창장</li>
          <li>2024 한국여성공인회계사회, 회계&세무 저널상</li>

        </div>
      </div>
    </div>


  </section>
  <!-- 사무실 컴포넌트 -->
  <div v-if="viewMore">
    <office></office>
  </div>

  <button v-if="menuBtn" class="btn  btn-lg  btn-primary my-5 mobileBtn floating-btn " @click="toggleMenuOn"
    id="mobileBtn">전체 서비스</button>


</template>

<script>
import office from './Office.vue'

export default {
  name: 'Main-a',

  data() {
    return {
      viewMore: false,
      menuBtn: true
    }
  },

  components: {
    office: office,
  },

  methods: {
    toggleMenuOn() {
      //Navbar로 요청함
      this.menuBtn = false
      this.emitter.emit('toggleMenuOn')
    },
  },

  mounted() {
    let observer = new IntersectionObserver((e) => {
      e.forEach((box) => {
        if (box.isIntersecting) {
          this.viewMore = true
        }
      })
    })
    let profileTitle = document.getElementById('profileTitle')
    observer.observe(profileTitle)
  }

}
</script>

<style scoped>
.floating-btn {
  position: fixed;
  bottom: 20px;
  /* 화면 하단에서 20px 위 */
  left: 50%;
  /* 화면의 가로 중앙으로 이동 */
  transform: translateX(-50%);
  /* 요소의 가로 크기만큼 왼쪽으로 이동하여 중앙 정렬 */
  z-index: 1000;
  /* 다른 요소 위에 떠 있도록 z-index 설정 */
  padding: 15px 25px;
  /* 버튼의 내부 여백 */
  border-radius: 50px;
  /* 둥근 모서리 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* 부드러운 그림자 효과 */
}

.floating-btn:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  /* 호버 시 그림자 효과 증가 */
}

.mainSection2 {
  background: #F7F9FF;
  padding-bottom: 30px;
  padding-top: 30px;
}


.profileBox {
  max-width: 500px;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  /* 자식 요소들 가운데 정렬 */
}

.profileBox img {
  display: inline-block;
  /* 이미지가 가운데 정렬되도록 설정 */
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .profileBox img {
    max-width: 80%;
    /* 모바일에서 이미지 크기 줄임 */
  }
}

.profile {
  font-size: 1.2rem;
}

.profile ul {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.profileTitle li {
  margin: auto;
  text-align: center;
  margin-bottom: 10px;
  /* line-height: 1.6; */
  list-style-type: none;
}

.introduce {
  font-size: 1.2rem;
  word-break: keep-all;
  /* 단어가 끊어지지 않도록 설정 */
  hyphens: none;
  /* 하이픈 없이 줄바꿈 */
}

.introduce span {
  color: var(--primary);
}

.introduceMobile {
  font-size: 1.2rem;
  word-break: keep-all;
  /* 단어가 끊어지지 않도록 설정 */
  hyphens: none;
  /* 하이픈 없이 줄바꿈 */
  display: none;
}

.introduceMobile span {
  color: var(--primary);
}

@media (max-width : 991px) {
  .introduce {
    display: none;
  }

  .introduceMobile {
    display: block;
  }
}


.serviceBox {
  padding: 40px
}

.serviceImageBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  overflow: hidden;
}

.greeting {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageTitle {
  font-size: 3rem;
  margin-bottom: 15px;
  color: var(--primary);
  font-weight: bold;
}

.pageContent {
  font-size: 1.5rem;
}

.lottieTest {
  height: 30vw;
  min-height: 400px;
}

.mobileBtn {
  display: none !important;
}

@media (max-width : 768px) {
  .mobileBtn {
    display: block !important;
    margin: auto;
    animation: none;
  }
}

@keyframes blink {
  from {
    opacity: 0.5;
    /* transform: rotate(1deg); */
  }

  to {
    opacity: 1;
    /* transform: rotate(-1deg); */
  }
}
</style>