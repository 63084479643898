<template>
  <section class="requestSection1">
    <div class="container">
      <h3 class="pageTitle pt-5 pb-3">경정청구</h3>


      <p>국세 기본법 제45조의 2제1항에 따라<br>사업자가 법정신고 한 날부터 <br>
        <span><b>5년 이내 신고분에 대해</b></span>
        <br><span><b>더 납부한 세금을 환급</b></span> 신청하는 제도입니다.
      </p>

      <img src="https://easytax.easyshop.co.kr/externalLogo/logo.png" class="easyshopLogo mt-5">
    </div>
  </section>
  <section class="requestSection2">
    <div class="container">
      <div class="section section1">
        <h1>이미 많은 대표님들이<br> <span>세금환급</span>을 받았습니다.</h1>
        <button class="btn btn-primary my-5 btnRequest" @click="goEasyShop">세금환급
          대상확인</button>

        <img src="https://easytax.easyshop.co.kr/img/visual-1.svg" alt="환급사유별 국세환급금 경정청구 현황 (국세통계포털)"
          class="section1Image1">
      </div>
    </div>
  </section>
  <section class="requestSection3">
    <div class="container">
      <div class="section section2" style="position:relative">
        <h1>놓쳤던 공제, 감면 혜택들<br /><span>‘택스봇R’이 AI기술</span>로 <br />스마트하게
          찾아드립니다.</h1>

        <img class="section2Image1 mt-5" src="https://easytax.easyshop.co.kr/img/visual-2.svg" alt="" />
        <button class="btn btn-primary my-5 btnRequest" @click="goEasyShop">세금환급
          대상확인</button>
      </div>
    </div>

  </section>
  <section class="requestSection4">
    <div class="container">
      <div class="section section4">
        <h1>세금 환급 경정청구 절차</h1>
        <h5>
          환급대상 간편확인은 ‘택스봇R’ AI솔루션으로,<br />
          복잡한 경정청구는 전문 세무사가 도와드려요.<br /><br /><br />
        </h5>
        <div class="section4Images d-flex flex-column flex-md-row flex-wrap justify-content-between col-12 col-lg-6 mx-auto">
  <div class="section4Image my-2 flex-fill">
    <img src="https://easytax.easyshop.co.kr/img/visual-3-1.svg" alt="" class="img-fluid" />
  </div>
  <div class="section4Image my-2 flex-fill">
    <img src="https://easytax.easyshop.co.kr/img/visual-3-2.svg" alt="" class="img-fluid" />
  </div>
</div>
      </div>
    </div>

  </section>
  <section class="requestSection5">
    <div class="container">
      <div class="section section5">
        <h1>세금 환급(경정청구) Q & A</h1>
        <div class="accordion accordion-flush col-12 col-lg-6 mx-auto pt-3" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                사업자 세금 환급이란?
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">사업자가 납세 신고를 한 날로부터 5년 이내, 과거 세제 혜택에 대한 착오, 누락, 자료 미비 등으로 더 납부한 세금 환급을 국세청에
                청구하는
                제도입니다. (국세 기본법 제 45조 2제1항)</div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                세금 환급이 발생하는 이유는?
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">세무사 사무소의 기장 위주 업무 진행과 매년 변경되는 새로운 개정 세법, 조세법으로 인한 경정청구에 대한 인지 부족이 주된 이유입니다.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                세금 환급 대상 사업자 확인 방법은?
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">불편한 서류 제출없이 세금환급 대상 사업자 확인을 간편하게 처리하는 경정청구 전문 AI솔루션을 무료로 이용하세요.</div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                세금 환급 진행은 전문 세무사 도움이 필요한가요?
              </button>
            </h2>
            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">복잡하고 다양한 형태의 법인 및 개인 사업자의 세액 공제 및 세액 감면 항목들은 꼼꼼한 법률 검토와 절차 진행을 전문으로 하는 믿을 수 있는
                세무사의 도움이 필요합니다.</div>
            </div>
          </div>
        </div>

        <button class="btn btn-primary my-5 btnRequest" @click="goEasyShop">세금환급
          대상확인</button>
      </div>
    </div>

  </section>


</template>


<script>
export default {
  name: 'Request-a',

  methods: {
    goEasyShop() {
      window.open("https://easytax.easyshop.co.kr/loginkakao", "_blank");
    }
  },

  mounted() {
    let observer = new IntersectionObserver((e) => {
      e.forEach((divBtn) => {
        if (divBtn.isIntersecting) {
          divBtn.target.style.animation = 'blink 0.5s 5';
          console.log(divBtn.target.style.animation)
        }
        else {
          divBtn.target.style.animation = 'none';
          console.log(divBtn.target.style.animation)
        }
      })
    })
    let divBtn = document.querySelectorAll('button.btnRequest')
      observer.observe(divBtn[0])
      observer.observe(divBtn[1])
      observer.observe(divBtn[2])
      // console.log(divBtn[0])
      // console.log(divBtn[1])
      // console.log(divBtn[2])
  }

}
</script>


<style scoped>
a {
  text-decoration-line: none;
  color: #fff;
  width: fit-content;
}


h1 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 800;
  color: #181818;
}

h4 {
  font-size: 20px;
  font-weight: 800;
  color: #181818;
}

span {
  color: #0d6efd;
}

.pageTitle {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: var(--primary);
  font-weight: bold;
}

.pageContent {
  font-size: 1.5rem;
}

.accordion-item {
  font-size: 20px;
}

.accordion-button {
  font-size: 20px;
}

.section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.requestSection3 {
  background: #F7F9FF;
}

.section4 img {
  margin-left: 10px;
  margin-right: 10px;
}

.easyshopLogo {
  width: 50%;
  max-width: 400px;
}

.btnRequest {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  width: 90%;
  max-width: 600px;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  animation: none;
}


/* 반응형 박스사이즈 비율 유지 */
.btnRequest::after {
  content: "";
  display: block;
  padding-bottom: 10%;
}


.section1Image1 {
  width: 80%;
  max-width: 600px;
}

.section2Image1 {
  width: 80%;
  max-width: 600px;
}

.section4Image {
  margin: auto;
}

.section4Image img {
  width: 300px;
}
</style>