<template>

  <div class="container">

    <!-- 페이지 헤더 -->
    <div class="row">
      <div class="col">
        <h3 class="pageTitle pt-5">자금 조달 서비스</h3>
        <p class="p-2 col-10 col-md-8 col-lg-6 mx-auto keep-all">기업이 운영과 성장에 필요한 자금을 확보할 수 있도록 돕는 서비스로, 기업의 재무 상황을 분석해 적절한 자금 조달 방법을 제안하고 실행을 지원합니다</p>
      </div>
    </div>

    <div class="카드들컨테이너 p-2">
    <div class="row">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="col-12 col-md-6 col-lg-4 mb-4 d-flex align-items-stretch"
      >
        <div v-if="index<5" class="border shadow-sm p-3 w-100 d-flex flex-column" style="border-radius: 10px;">
          <div class="row flex-grow-1">
            <div class="col-12 d-flex justify-content-center mb-3">
              <iframe :src="step.lottie" width="400px"></iframe>
            </div>
            <div class="col-12 keep-all">
              <h3 class="mb-3"><b>{{ step.title }}</b></h3>
              <p>{{ step.description }}</p>
            </div>
          </div>
        </div>
        <!-- 마지막카드 -->
        <div  @click="openLink" v-if="index==5" class=" text-white bg-primary my-card border shadow-sm p-3 w-100  d-flex flex-column justify-content-center align-items-center" style="border-radius: 10px;">
          <i class="fas fa-arrow-pointer fa-2x mb-3 animate-icon"></i>
              <h3><b>자금조달 신청</b></h3>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>


export default {
  name: "Financing-a",
  mounted() {

  },
  methods: {
    openLink() {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLScHmoCvRzIX9CUEm7ma1lIIZpVyly_PPf4JnxFLDu6qlTm-kg/viewform?usp=sf_link', '_blank');
    }
  },
  data() {
    return {
      steps: [
        {
          title: "1단계: 현 상황에 대한 이해",
          description:
            "사업의 현재 상황과 문제를 철저히 분석하여 자금 조달의 필요성을 명확히 합니다. 현금 흐름, 재무 구조, 시장 상황 등을 종합적으로 평가합니다.",
          lottie: 'https://lottie.host/embed/a45dc870-2342-4f8b-92ac-257559498b41/i7OoQHnjen.json'
        },
        {
          title: "2단계: 맞춤형 해결책 제안",
          description:
            "기업의 상황에 맞는 자금 조달 솔루션을 제안합니다. 이는 벤처 캐피탈, 정부지원 저금리 정책자금 조달등 다양한 자금 조달 방법을 포함합니다.",
          lottie: 'https://lottie.host/embed/67d82750-e839-4c4d-b16e-38a6d575870e/PAMLgbVYaL.json'
        },
        {
          title: "3단계: 자금조달 스케쥴 설계",
          description:
            "기업의 필요자금 스케줄에 따라 최적의 자금 조달을 위해 맞춤형 스케줄을 설계하여, 자금 흐름을 효과적으로 관리해 드립니다.",
          lottie: 'https://lottie.host/embed/77ec5655-ef76-4283-a96b-f4ab39841668/ZhH14AUBFU.json'
        },
        {
          title: "4단계: 위험 관리와 안정성",
          description:
            "자금 조달 과정에서 발생할 수 있는 다양한 리스크를 관리하고, 기업의 재정적 안정성을 보장하는 전략을 마련합니다.",
          lottie: 'https://lottie.host/embed/0607fc0f-eeb2-48ff-90b2-652e7a123e28/4kfZ89NkXO.json'
        },
        {
          title: "5단계: 지속적인 지원과 성과 모니터링",
          description:
            "자금 조달 후에도 지속적인 성과 모니터링과 지원을 통해, 기업이 성공적으로 성장할 수 있도록 돕습니다.",
          lottie: 'https://lottie.host/embed/0555259a-8c48-44aa-93dc-ff343558ecf1/XpleTUgaz0.json'
        },
        {
          title: "바로가기버튼",
          description:
            "",
          lottie: ''
        },
      ],
    };
  },
};
</script>

<style>

.pageTitle {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: var(--primary);
  font-weight: bold;
}


.my-card  {
  transition: 0.2s all;
  cursor: pointer; /* 부드러운 전환 효과 추가 */
}

.my-card:hover {
  transform: scale(1.01); /* 마우스를 올렸을 때 약간 확대 */
  background-color: #2c59ff !important;   /* 배경색 변경 */
}

@keyframes move-in-circle {
  0% {
    transform: translate(7px, 0px); /* 시작점 (오른쪽) */
  }
  25% {
    transform: translate(0px, 7px); /* 아래쪽 */
  }
  50% {
    transform: translate(-7px, 0px); /* 왼쪽 */
  }
  75% {
    transform: translate(0px, -7px); /* 위쪽 */
  }
  100% {
    transform: translate(7px, 0px); /* 다시 시작점 (오른쪽) */
  }
}

.keep-all {
  word-break: keep-all;
}

.animate-icon {
  display: inline-block;
  animation: move-in-circle 2s linear infinite;
}

</style>
