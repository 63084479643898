<template>

  <nav class="navbar mb-3">
    <div class="container">

      <img src="@/assets/신흥세무회계로고.png" alt="로고" class="navbar-logo"><router-link to="/" class="navbar-brand">신흥세무회계</router-link>
      <ul class="navnav navbar-nav">
        <li class="nav-item" v-for="(a, i) in pages" :key="i">
          <!-- 조건: '블로그'인 경우 -->
          <a v-if="a.name === '블로그'" :href="'https://blog.naver.com/seedkim1012'" class="nav-link" target="_blank">
            {{ a.name }}
          </a>
          <!-- 다른 페이지인 경우 -->
          <router-link v-else :to="`${a.to}`" class="nav-link">
            {{ a.name }}
          </router-link>
        </li>
      </ul>

      <!-- 상담 버튼 -->
      <button class="btn btn-primary ms-auto mx-2" @click="contact" id="btn-contact">상담하기</button>
      <!-- 햄버거 버튼 -->
      <button class="navbar-toggler" type="button" @click="MenuModal">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>
  </nav>

  <!-- 모바일 메뉴창 -->
  <div class="black-bg" v-if="navbarState" @click="MenuModal">
    <div class="white-bg">
      <ul class="navbar-nav toggleNav">
        <router-link to="/" class="navbar-brand my-2">신흥세무회계</router-link>
        <li class="nav-item" v-for="(a, i) in pages" :key="i">
          <!-- 조건: '블로그'인 경우 -->
          <a v-if="a.name === '블로그'" :href="'https://blog.naver.com/seedkim1012'" class="nav-link" target="_blank">
            {{ a.name }}
          </a>
          <!-- 다른 페이지인 경우 -->
          <router-link v-else :to="`${a.to}`" class="nav-link">
            {{ a.name }}
          </router-link>
        </li>
        <button class="btn btn-primary toggleNavCall" @click="contact()" id="btn-contact">상담하기</button>
      </ul>
    </div>
  </div>


  <contact></contact>


</template>

<script>
import contact from './Contact.vue'

export default {
  name: 'nav-a',

  components: {
    contact
  },

  data() {
    return {

      navbarState: false,

      pages: [
        { name: '회사소개', to: '/' },
        { name: '경정청구', to: '/request' },
        // { name: '자금조달', to: '/financing' },
        { name: '기장대리', to: '/tax' },
        { name: '블로그', to: '' },
      ]
    }
  },

  methods: {

    contact() {
      this.emitter.emit('contact', '일반')
    },


    MenuModal() {
      this.navbarState = !this.navbarState

    }
  },
  mounted() {

    //메인화면에서 요청 옴
    this.emitter.on('toggleMenuOn', () => {
      this.navbarState = true
    })
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  z-index: 100;
}

.white-bg {
  position: absolute;
  right: 0;
  width: 60%;
  height: 100%;
  background: white;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  animation-duration: 0.5s;
  animation-name: modal;
}


.navbar-logo {
  height: 50%; /* navbar 높이에 맞추기 */
  max-height: 25px; /* 로고 이미지의 최대 높이 제한 */
  margin-right: 3px;
}

@keyframes modal {
  from {
    margin-left: 100%;
    width: 0%
  }

  to {
    margin-left: 0%;
    width: 60%;
  }
}

.toggleNav {
  font-size: 1.2rem;
}

.toggleNavItem:hover {
  font-weight: 500;
  color: #0d6efd;
  animation-duration: 0.5s;
  animation-name: toggleNavItemSelect;
}

@keyframes toggleNavItemSelect {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

.toggleNavCall {
  margin-top: 20px;
  width: 150px;
}

.navbar {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 99;
}

.navbar-brand {
  font-weight: bold;
}

.navnav {
  /* margin: auto; */
  flex-direction: row !important;
}

.nav-item {
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav-item:hover {
  font-weight: 500;
  color: #0d6efd;
  animation-duration: 0.5s;
  animation-name: toggleNavItemSelect;
}

.navbar-toggler {
  display: none;
}

/* 768px 보다 작으면 아래 적용됨 */
@media (max-width : 768px) {
  .navnav {
    display: none !important;
  }

  .navbar-toggler {
    display: contents;
  }
}
</style>