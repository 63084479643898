import { createStore } from 'vuex'

const store = createStore({
  state(){
    return {
        newGuests:0,
        existGuest: false,
    }
  },

  mutations:{
    newGuestsChange(state,payload){
        state.newGuests = payload
        if(payload==0){
            state.existGuest=false
        }
        else{
            state.existGuest=true
        }
    }
  }

})

export default store