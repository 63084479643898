<template>

<navbar></navbar>
<router-view></router-view>
<footerbar></footerbar>

</template>


<script>

import navbar from './components/Navbar.vue'
import footerbar from './components/Footerbar.vue'

export default {
  name: 'App',
  components: {
    navbar,footerbar
  },

  mounted() {
  },
}
</script>

<style>
/* 구글 폰트 설치 */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300&display=swap');

#app {
  font-family: 'Noto Sans KR', sans-serif,Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

:root{
  /* color */
  --primary : #0d6efd;
  
  /* 부트스트랩 설치로 인해 스크롤 부드럽게 이동되는 효과 제거 */
  scroll-behavior: auto;
}

</style>
